export enum MatchTypes {
	BOTH = 'both',
	HOME = 'home',
	AWAY = 'away',
}

export const WidgetTeamSquadDefaultHeader = (t: any) => {
	return [
		{ value: 'grid', label: t('grid'), default: false },
		{ value: 'list', label: t('list'), default: true },
	];
};

export const WidgetTeamProgrammeMatchTypeOptions = (t: any) => {
	return [
		{ value: 'home', label: t('home'), default: false },
		{ value: 'away', label: t('away'), default: false },
		{ value: 'both', label: t('both'), default: true },
	];
};

export const matchCenterActiveTabOptions = (t: any) => {
	return [
		{ label: t('overview'), value: 'overview', default: true },
		{ label: t('details'), value: 'details', default: false },
		{ label: t('squads'), value: 'squads', default: false },
		{ label: t('stats'), value: 'stats', default: false },
		{ label: t('standings'), value: 'standings', default: false },
		{ label: t('odds'), value: 'odds', default: false },
		{ label: t('h2h'), value: 'h2h', default: false },
	];
};

export const teamProfileStatsTabOptions: string[] = ['played', 'win', 'goals_scored', 'goals_conceded', 'draw', 'defeats', 'rank', 'points'];

export const playerProfileStatsTabOptions: string[] = [
	'played',
	'goals',
	'assists',
	'minutes',
	'started',
	'conceded',
	'red_cards',
	'cleansheets',
	'yellow_cards',
	'substitute_in',
	'substitute_out',
	'goals_substitute',
	'minutes_substitute',
	'penalty_goals',
	'own_goals', //
	'shots',
	'shots_on_target',
	'offsides',
	'fouls_committed',
	'penalties_committed',
	'penalties_saved',
	'penalties_missed',
	'penalties_received',
	'caught_ball', //
	'saves', //
];

export const defaultForwarderStatistics = ['played', 'goals', 'shots', 'assists', 'minutes', 'yellow_cards'];
export const defaultKeeperStatistics = ['played', 'saves', 'cleansheets', 'conceded', 'caught_ball', 'penalties_saved'];
export const defaultTeamStatistics = ['played', 'win', 'draw', 'defeats', 'goals_scored', 'goals_conceded'];

export const playerH2HStatsOptions: string[] = [
	'played',
	'minutes',
	'assists',
	'red_cards',
	'yellow_cards',
	'clean_sheets',
	'fouls_committed',
	'goals',
	'goals_substitute',
	'minutes_substitute',
	'offsides',
	'own_goal',
	'penalties_committed',
	'penalties_missed',
	'penalties_received',
	'penalties_saved',
	'penalty_goals',
	'shots',
	'shots_on_target',
	'started',
	'substitute_in',
	'substitute_out',
];

export const teamH2HStatsOptions: string[] = ['played', 'goalsScored', 'win', 'draw', 'goalsConceded', 'defeats', 'points', 'rank'];

export const teamMatchStatsOptions: string[] = [
	'corners',
	'counterAttacks',
	'crosses',
	'foulsCommitted',
	'goalKicks',
	'goals',
	'offside',
	'possession',
	'redCards',
	'yellowCards',
	'shotsBlocked',
	'shotsOff',
	'shotsOn',
	'substitutions',
	'throwIn',
	'treatments',
];

export const topScorersStatsOptions: string[] = [
	'played',
	'red_cards',
	'assists',
	'minutes',
	'missed_penalties',
	'penalties',
	'yellow_cards',
	'scored_first',
];

export const teamSquadStatsOptions: string[] = [
	'age',
	'matches_played',
	'minutes',
	'minutes_substitute',
	'goals',
	'started',
	'assists',
	'yellow_cards',
	'red_cards',
	'conceded',
	'cleansheets',
];
